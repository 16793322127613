<script>
import Layout from "../../layouts/main.vue"
import appConfig from "../../../app.config"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import Swal from "sweetalert2"
import uploadFile from "@/components/file/upload-file.vue"

export default {
  page: {
    title: "Documentos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      value: "File Type",
      folders: [
        {
          name: "Projects",
          files: "349",
          storage: "4.10",
          ischecked: false,
        },
        {
          name: "Documents",
          files: "2348",
          storage: "27.01",
          ischecked: false,
        },
        {
          name: "Media",
          files: "12480",
          storage: "20.87",
          ischecked: false,
        },
        {
          name: "Velzon v1.7.0",
          files: "180",
          storage: "478.65MB",
          ischecked: true,
        },
      ],

      filelist: [
        {
          id: 1,
          icon: "ri-gallery-fill",
          iconClass: "success",
          fileName: "logos/logoAzulSemS.png",
          fileType: "Media",
          fileItem: "01",
          fileSize: "1.3 MB",
          date: "24 May, 2022",
        },
        {
          id: 2,
          icon: "ri-file-pdf-fill",
          iconClass: "danger",
          fileName: "velzon-invoice.pdf",
          fileType: "Documents",
          fileItem: "01",
          fileSize: "1.1 MB",
          date: "05 May, 2022",
        },
        {
          id: 3,
          icon: "ri-folder-2-fill",
          iconClass: "warning",
          fileName: "Velzon React",
          fileType: "Media",
          fileItem: "367",
          fileSize: "934 MB",
          date: "28 Apr, 2022",
        },
        {
          id: 4,
          icon: "ri-file-text-fill",
          iconClass: "secondary",
          fileName: "html.docx",
          fileType: "Documents",
          fileItem: "01",
          fileSize: "0.3 KB",
          date: "19 Apr, 2022",
        },
        {
          id: 5,
          icon: "ri-gallery-fill",
          iconClass: "success",
          fileName: "default.jpg",
          fileType: "Media",
          fileItem: "01",
          fileSize: "1.7 MB",
          date: "06 Apr, 2022",
        },
      ],

      series: [27.01, 20.87, 33.54, 37.58],
      chartOptions: {
        chart: {
          height: 300,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
        }
      },
    }
  },
  components: {
    Layout,
    Multiselect,
    uploadFile
  },
  watch: {
    folders() { },
    filelist() { },
  },
  methods: {
    changefolder(title) {
      document.getElementById("folder-list").style.display = "block"
      document.getElementById("filetype-title").innerHTML = title
    },
    changerecent(title) {
      document.getElementById("folder-list").style.display = "none"
      document.getElementById("filetype-title").innerHTML = title
    },
    changetitle(title) {
      document.getElementById("filetype-title").innerHTML = title
    },
    createfolder() {
      var data = {
        name: document.getElementById("foldername-input").value,
        files: "0",
        storage: "0",
        ischecked: false,
      }
      document.getElementById("addFolderBtn-close").click()
      this.folders.unshift(data)
    },
    createfile() {
      var fileName = document.getElementById("filename-input").value
      var uniqueid = Math.floor(Math.random() * 100)

      if (fileName !== "") {
        var data = {
          id: uniqueid,
          fileName: fileName + ".txt",
          filetype: "Documents",
          fileItem: "01",
          fileSize: "0 KB",
          date: new Date().toUTCString().slice(5, 16),
          starred: false,
        }
        document.getElementById("addFileBtn-close").click()
        this.filelist.unshift(data)
      }
    },
    deletefolder(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.folders.splice(this.folders.indexOf(event), 1)
          Swal.fire("Deleted!", "Your file has been deleted.", "success")
        }
      })
    },
    deletefile(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.filelist.splice(this.filelist.indexOf(event), 1)
          Swal.fire("Deleted!", "Your file has been deleted.", "success")
        }
      })
    },
    toggleFavourite(ele) {
      ele.target.closest(".favourite-btn").classList.toggle("active")
    },
  },
}
</script>

<template>
  <Layout>
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="file-manager-content w-100 p-3 py-0">
        <div class="mx-n3 pt-4 px-4 file-manager-content-scroll" data-simplebar>
          <div id="folder-list" class="mb-2">
            <div class="row justify-content-beetwen g-2">
              <div class="col">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 me-2 d-block d-lg-none">
                    <button type="button" class="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                      <i class="ri-menu-2-fill align-bottom" />
                    </button>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="fs-16 mb-0">Pastas</h5>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex gap-2 align-items-start mb-2">
                  <Multiselect class="form-control w-lg" v-model="value" :options="[
                    'File Type',
                    'All',
                    'Video',
                    'Images',
                    'Music',
                    'Documents',
                  ]" />
                  <button class="btn btn-success create-folder-modal text-nowrap" data-bs-toggle="modal"
                    data-bs-target="#createFolderModal">
                    <i class="ri-add-line align-bottom me-1" /> Criar Pasta
                  </button>
                </div>
              </div>
            </div>
            <div class="row" id="folderlist-data">
              <div v-for="(folder, index) of this.folders" :key="index" class="col-xxl-3 col-6 folder-card">
                <div class="card bg-light shadow-none" id="folder-1">
                  <div class="card-body">
                    <div class="d-flex mb-1">
                      <div class="form-check form-check-danger mb-3 fs-15 flex-grow-1">
                        <input class="form-check-input" type="checkbox" value="" id="folderlistCheckbox_1" checked
                          v-if="folder.ischecked" />
                        <input class="form-check-input" type="checkbox" value="" id="folderlistCheckbox_1"
                          v-if="!folder.ischecked" />
                        <label class="form-check-label" for="folderlistCheckbox_1"></label>
                      </div>
                      <div class="dropdown">
                        <button class="btn btn-ghost-primary btn-icon btn-sm dropdown" type="button"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="ri-more-2-fill fs-16 align-bottom" />
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <a class="dropdown-item view-item-btn" href="javascript:void(0)">Open</a>
                          </li>
                          <li>
                            <a class="dropdown-item edit-folder-list" href="#createFolderModal" data-bs-toggle="modal"
                              role="button">Rename</a>
                          </li>
                          <li>
                            <a class="dropdown-item" role="button" @click="deletefolder(folder)">Delete</a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="text-center">
                      <div class="mb-2">
                        <i class="ri-folder-2-fill align-bottom text-warning display-5" />
                      </div>
                      <h6 class="fs-15 folder-name">{{ folder.name }}</h6>
                    </div>
                    <div class="hstack mt-4 text-muted">
                      <span class="me-auto"><b>{{ folder.files }}</b> Files</span>
                      <span><b>{{ folder.storage }}</b>GB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center mb-3">
              <h5 class="flex-grow-1 fs-16 mb-0" id="filetype-title">
                Recent File
              </h5>
              <div class="flex-shrink-0">
                <button class="btn btn-success createFile-modal" data-bs-toggle="modal" data-bs-target="#createFileModal">
                  <i class="ri-add-line align-bottom me-1" />
                  Adicionar Arquivo
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-active">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">File Item</th>
                    <th scope="col">File Size</th>
                    <th scope="col">Recent Date</th>
                    <th scope="col" class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody id="file-list">
                  <tr v-for="(fileData, index) of this.filelist" :key="index">
                    <td>
                      <input class="form-control filelist-id" type="hidden" value="{{fileData.id}}"
                        id="filelist-{{fileData.id}}" />
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 fs-17 me-2 filelist-icon">
                          <i class="ri-gallery-fill align-bottom text-success" v-if="
                            fileData.fileName.includes('.') &&
                            fileData.fileName.split('.')[1] == 'png'
                          " />
                          <i class="ri-gallery-fill align-bottom text-success" v-if="
                            fileData.fileName.includes('.') &&
                            fileData.fileName.split('.')[1] == 'jpg'
                          " />
                          <i class="ri-file-pdf-fill align-bottom text-danger" v-if="
                            fileData.fileName.includes('.') &&
                            fileData.fileName.split('.')[1] == 'pdf'
                          " />
                          <i class="ri-file-text-fill align-bottom text-secondary" v-if="
                            fileData.fileName.includes('.') &&
                            fileData.fileName.split('.')[1] == 'docx'
                          " />
                          <i class="ri-file-text-fill align-bottom text-secondary" v-if="
                            fileData.fileName.includes('.') &&
                            fileData.fileName.split('.')[1] == 'txt'
                          " />
                          <i class="ri-file-text-fill align-bottom text-secondary"
                            v-if="!fileData.fileName.includes('.')" />
                        </div>
                        <div class="flex-grow-1 filelist-name">
                          {{ fileData.fileName }}
                        </div>
                        <div class="d-none filelist-type">
                          {{ fileData.filetype }}
                        </div>
                      </div>
                    </td>
                    <td>{{ fileData.fileItem }}</td>
                    <td class="filelist-size">{{ fileData.fileSize }}</td>
                    <td class="filelist-create">{{ fileData.date }}</td>
                    <td>
                      <div class="d-flex gap-3 justify-content-center">
                        <button type="button" class="btn btn-ghost-primary btn-icon btn-sm favourite-btn active"
                          v-if="fileData.starred" @click="togglefavorite">
                          <i class="ri-star-fill fs-13 align-bottom" />
                        </button>
                        <button type="button" class="btn btn-ghost-primary btn-icon btn-sm favourite-btn"
                          v-if="!fileData.starred">
                          <i class="ri-star-fill fs-13 align-bottom" />
                        </button>
                        <div class="dropdown">
                          <button class="btn btn-light btn-icon btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-bottom" />
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item viewfile-list" href="#">View</a>
                            </li>
                            <li>
                              <a class="dropdown-item edit-list" href="#createFileModal" data-bs-toggle="modal"
                                data-edit-id="+ fileData.id + " role="button">Rename</a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li>
                              <a class="dropdown-item remove-list" role="button" @click="deletefile(fileData)">Delete</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul id="pagination" class="pagination pagination-lg"></ul>
            <div class="align-items-center mt-2 row g-3 text-center text-sm-start">
              <div class="col-sm">
                <div class="text-muted">
                  Showing<span class="fw-semibold">4</span> of
                  <span class="fw-semibold">125</span> Results
                </div>
              </div>
              <div class="col-sm-auto">
                <ul
                  class="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                  <li class="page-item disabled">
                    <a href="#" class="page-link">←</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">1</a>
                  </li>
                  <li class="page-item active">
                    <a href="#" class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">→</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade zoomIn" id="createFolderModal" tabindex="-1" aria-labelledby="createFolderModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header p-3 bg-soft-success">
            <h5 class="modal-title" id="createFolderModalLabel">
              Create Folder
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" id="addFolderBtn-close" aria-label="Close" />
          </div>
          <div class="modal-body">
            <form autocomplete="off" class="needs-validation createfolder-form" id="createfolder-form" novalidate>
              <div class="mb-4">
                <label for="foldername-input" class="form-label">Folder Name</label>
                <input type="text" class="form-control" id="foldername-input" required />
                <div class="invalid-feedback">Please enter a folder name.</div>
                <input type="hidden" class="form-control" id="folderid-input" value="" />
              </div>
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-ghost-success" data-bs-dismiss="modal">
                  <i class="ri-close-line align-bottom" /> Close
                </button>
                <button type="button" class="btn btn-primary" id="addNewFolder" @click="createfolder">
                  Add Folder
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade zoomIn" id="createFileModal" tabindex="-1" aria-labelledby="createFileModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header p-3 bg-soft-success">
            <h5 class="modal-title" id="createFileModalLabel">Create File</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" id="addFileBtn-close" aria-label="Close" />
          </div>
          <uploadFile />
        </div>
      </div>
    </div>

    <div id="removeFileItemModal" class="modal fade zoomIn" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              id="close-removefilemodal" />
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548" style="width: 100px; height: 100px"></lord-icon>
              <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p class="text-muted mx-4 mb-0">
                  Are you sure you want to remove this item ?
                </p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn w-sm btn-danger" id="remove-fileitem">
                Yes, Delete It!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="removeFolderModal" class="modal fade zoomIn" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              id="close-removeFoldermodal" />
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548" style="width: 100px; height: 100px"></lord-icon>
              <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p class="text-muted mx-4 mb-0">
                  Are you sure you want to remove this folder ?
                </p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn w-sm btn-danger" id="remove-folderList">
                Yes, Delete It!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>