<template>
  <div class="modal-body">
    <div class="align-items-center">
      <div class="mb-4 vstack gap-2">
        <h5 class="fs-14 mb-1">Anexe os documentos desejados</h5>
        <div class="dropzone position-relative" @dragover="dragover" @dragleave="dragleave" @drop="drop" @paste="onPaste">
          <div class="mb-1">
            <i class="display-4 text-muted ri-upload-cloud-2-fill" />
          </div>
          <input type="file" multiple name="file" :id="radomInputId" class="hidden-input" @change="onChange" ref="file"
            accept=".pdf,.jpg,.jpeg,.png" />
          <div v-if="isDragging">
            <h5>Solte aqui os Arquivos.</h5>
          </div>
          <div v-else>
            <h5 class="text-center">Solte arquivos aqui ou clique para inseri-los.</h5>
          </div>
          <label :for="radomInputId" class="bg-light text-dark stretched-link file-label">Inserir</label>
        </div>
        <div v-for="file in files" :key="file.name" class="preview-card border rounded">
          <div class="d-flex flex-column flex-lg-row align-items-center p-2">
            <b-img v-if="file.type != 'application/pdf'" class="img-thumbnail me-2" alt="200x200" width="200"
              :src="generateURL(file)" data-holder-rendered="true" />
            <iframe v-else class="img-thumbnail me-2" data-holder-rendered="true" frameBorder="0" scrolling="no"
              alt="200x200" width="200" :src="generateURL(file)" />
            <div class="flex-grow-1">
              <div class="pt-1">
                <h5 class="fs-11 mb-1" data-dz-name="">
                  {{ file.name }}
                </h5>
                <p class="fs-9 text-muted mb-2 mb-lg-0" data-dz-size="">
                  <strong>{{ ((file.size / 1024) / 1000).toFixed(2) }}</strong> MB
                </p>
                <p class="fs-9 text-muted mb-0" data-dz-size="">
                  <textarea rows="2" class="form-control form-control-sm" placeholder="Descrição"
                    v-model="file.descricao" />
                </p>
              </div>
            </div>
            <div class="flex-shrink-0 ms-3 mt-2 mt-lg-0">
              <b-button-group>
                <b-button class="btn btn-sm btn-danger" @click="excluir(files.indexOf(file))">
                  Excluir
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    updateFile: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      files: [],
      radomInputId: Math.random().toString(36).substring(7)
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].addEventListener('paste', this.onPaste)
  },
  watch: {
    updateFile() {
      if(this.updateFile.length == 1) {
        this.files = []
      }
    }
  },
  methods: {
    onChange(e) {
      this.$refs.file.files = e.target.files
      this.files.push(...this.$refs.file.files)
      this.updateFile(this.files)
    },
    onPaste(e) {
      if(!e.clipboardData.files.length) return
      this.$refs.file.files = e.clipboardData.files
      this.files.push(...this.$refs.file.files)
      this.updateFile(this.files)
    },
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.files.push(...this.$refs.file.files)
      this.updateFile(this.files)
      this.isDragging = false
    },
    excluir(i) {
      this.files.splice(i, 1)
    },
    generateURL(file) {
      if (file.type != 'application/pdf') {
        let fileSrc = URL.createObjectURL(file)
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return fileSrc
      }
      else {
        let fileSrc = new String(URL.createObjectURL(file))
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc)
        }, 1000)
        return fileSrc
      }
    }
  }
}
</script>